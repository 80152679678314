import { Component } from '@angular/core';
import {mateusz, Person} from './model/person';
import { HttpClient } from '@angular/common/http';
import { Router, NavigationEnd, ActivatedRoute } from '@angular/router';
import { filter, map } from 'rxjs/operators';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  title = 'cv-mateusz-bielak';

  ngOnInit() {
      // Get the full URL
      const href = window.location.href;
      console.log("Href:", href)

      if (href.includes('?')) {
          const queryString = href.split('?')[1];
          console.log("Query:", queryString)
          const params = new URLSearchParams(queryString);

          // Check if 'cv' parameter exists
          if (params.has('cv')) {
              const cvValue = params.get('cv');
              const cvUrl = queryString;
              this.loadData(cvValue, cvUrl)

          } else {
              this.loadData('person')
          }
      } else {
          this.loadData('person')
      }

  }

  person: Person;

  loadData(personName: string, cvUrl: string = '') {
    this.http.get<Person>('assets/' + personName + '.json').subscribe(
        (response) => {
          console.log("Response:")
          console.log(response);
          this.person = response;
          this.person.www = (this.person.www + '?' + cvUrl);
          console.log(this.person);
        },
        (error) => {
          console.error("Could not load the json file.", error);
          // this.loadData('person')
        }
    );
  }
  constructor(private http: HttpClient, private router: Router, private activatedRoute: ActivatedRoute) {
      this.ngOnInit();
  }
}
